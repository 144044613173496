import Picture from '@/models/Picture';

export class Item {
  id;
  automatic;
  type;
  program;
  plan;
  selection;
  item_type_id;
  block_id;
  title;
  subtitles;
  show_cta_button;
  video;
  description;
  configuration;
  cta_label;
  cta_url;
  cta_target;
  rank;
  picture;
  details;
  buttons;
  itemable_id;
  itemable_type;
  metadata;
  custom;
  tag;
  category;
  person;
  country;
  gear;
  level;
  version;
  ratinglevel;
  format;

  // Setter à true quand on initialise l'item sans lui passer d'id
  newItem;
  // newItemId est un id auto généré pour définir de manière unique l'id du nouvel item,
  // de manière transverse à tous les items en cours de création
  newItemID;
  toRemove;
  form_field;

  textLengthValid;

  constructor({
    id,
    automatic,
    newItem,
    newItemID,
    type,
    program,
    plan,
    selection,
    item_type_id,
    block_id,
    title,
    subtitles,
    show_cta_button,
    video,
    description,
    configuration,
    cta_label,
    cta_url,
    cta_target,
    rank,
    picture = {},
    toRemove = false,
    details = [],
    buttons = [],
    itemable_id = null,
    itemable_type = null,
    metadata = null,
    custom = null,
    tag = null,
    category = null,
    person = null,
    country = null,
    gear = null,
    level = null,
    version = null,
    ratinglevel = null,
    format = null,
    metadata_detail = null,
    errors = null,
    duplicate = false,
    form_field = {}
  }) {
    this.automatic = automatic;
    this.type = type;
    this.program = program;
    this.plan = plan;
    this.selection = selection;
    this.item_type_id = item_type_id;
    this.block_id = block_id;
    this.title = title;
    this.subtitles = subtitles;
    this.show_cta_button = show_cta_button;
    this.video = video;
    this.description = description;
    this.cta_label = cta_label;
    this.cta_url = cta_url;
    this.cta_target = cta_target;
    this.rank = rank;
    this.toRemove = toRemove;
    this.details = details;
    this.buttons = buttons;
    this.itemable_id = itemable_id;
    this.itemable_type = itemable_type;
    this.metadata = metadata;
    this.custom = custom;
    this.tag = tag;
    this.category = category;
    this.person = person;
    this.country = country;
    this.gear = gear;
    this.level = level;
    this.version = version;
    this.ratinglevel = ratinglevel;
    this.format = format;
    this.metadata_detail = metadata_detail;
    this.errors = errors;
    this.setPicture(picture, duplicate);
    this.form_field = form_field;

    this.textLengthValid = true;

    if (configuration === null || configuration === undefined) {
      this.configuration = {
        align: 'right',
        movable: true,
        subtitles: [],
        video: null,
        hide_metas: false,
      };
    } else {
      this.configuration = configuration;
    }

    //duplicate
    if (this.itemable_type === 'plan' && this.plan?.billing_plan_id) {
      this.plan.id = this.plan.billing_plan_id;
    }

    //TODO
    // Lors de la création d'un nouvel item, il n'a pas d'id,
    // l'id ne sert QUE pour définir l'id sauvegardé en BDD.
    // On flag donc un nouvel item avec newItem = true et on défini un id temporaire newItemID
    if (typeof id !== 'number' && typeof newItemID !== 'number') {
      this.newItem = true;
      if (typeof this.block_id === 'number') {
        this.newItemID = parseInt(
          `${this.block_id}${10000 - Math.floor(Math.random() * 100)}`
        );
      } else {
        this.newItemID = parseInt(`${10000 - Math.floor(Math.random() * 500)}`);
      }
    } else if (typeof newItemID === 'number') {
      this.newItemID = newItemID;
      this.newItem = newItem;
    } else {
      this.id = id;
    }

    if (this.show_cta_button === undefined || this.show_cta_button === null) {
      if (this.configuration && 'show_cta_button' in this.configuration) {
        this.show_cta_button = this.configuration.show_cta_button;
      } else {
        this.show_cta_button = false;
      }
    }

    if (this.configuration && this.configuration.video) {
      this.video = this.configuration.video;
    }
    if (this.video === undefined) {
      this.video = null;
    }
  }

  setPicture(picture, duplicate = false) {
    this.picture = Picture.getDefaultPicture('item');
    const pictureTypes = Picture.getDefaultPictureTypesList('item');
    if (typeof picture === 'object') {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = picture[pictureType] ?? [];
        if (duplicate && this.picture[pictureType].length > 0) {
          this.picture[pictureType].forEach((picture) => {
            picture.id = null;
          });
        }
      });
    } else {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = [];
      });
    }
  }
}

export function createBlock(data) {
  return Object.freeze(new Item(data));
}

export default Item;
