import { handleResponse } from '@/services/index.js';
import axios from 'axios';

const api_url_module = '/business-rules/';

export const businessRuleService = {
  getItems,
  getBusinessRules,
};

async function getItems(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL +
      api_url_module +
      id + '/items'
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function getBusinessRules() {
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL +
      api_url_module
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}