import { devicesConfig as devices } from '@/data/devices';
import { isProxy, toRaw } from 'vue';
import getSymbolFromCurrency from 'currency-symbol-map';

/**
 * @prettier
 */

export const utils = {
  hasToBeFormatted: function (param) {
    const toBeFormatted = [
      'category',
      'tag',
      'director',
      'version',
      'level',
      'rating_level',
    ];
    return toBeFormatted.indexOf(param) !== -1;
  },

  arrayToIds: function (data) {
    let ids = [];
    if (Array.isArray(data)) {
      for (let index = 0; index < data.length; index++) {
        if (data[index].id && typeof data[index] === 'object') {
          ids.push(data[index].id);
        } else {
          return data;
        }
      }
      return ids;
    } else if (data?.id && typeof data === 'object') {
      return data.id;
    } else {
      return data;
    }
  },
  formatCategoryFacets: function (categories) {
    if (!categories) {
      return {};
    }
    const filters = [];
    for (let index = 0; index < categories.length; index++) {
      const category = categories[index];
      filters.push({
        checked: false,
        title: category.title,
        id: category.id,
      });
    }
    return filters;
  },
  makeQueryString: function (
    selectedFilter = [],
    selectedSort = '',
    pageNum = '',
    count = ''
  ) {
    let returnQueryFilter = [];
    let statusQuery = [],
      customerStatusQuery = [],
      formatQuery = [],
      categoryQuery = [],
      job_id = [],
      payments = [],
      plans = [];
    if (Array.isArray(selectedFilter) && selectedFilter.length > 0) {
      for (let index = 0; index < selectedFilter.length; index++) {
        const element = selectedFilter[index];
        if (element.facet_id === 'status') {
          statusQuery.push(element.id);
        }
        if (element.facet_id === 'customer_status') {
          customerStatusQuery.push(element.id);
        }
        if (element.facet_id === 'formats') {
          formatQuery.push(element.id);
        }
        if (element.facet_id === 'categories') {
          categoryQuery.push(element.id);
        }
        if (element.facet_id === 'job_id') {
          job_id.push(element.id);
        }
        if (element.facet_id === 'payments') {
          payments.push(element.id);
        }
        if (element.facet_id === 'plans') {
          plans.push(element.id);
        }
      }
      if (statusQuery.length > 1) {
        returnQueryFilter.push('status=' + 'in:' + statusQuery.join(','));
      } else if (statusQuery.length === 1 && statusQuery[0] !== '') {
        returnQueryFilter.push('status=' + statusQuery[0]);
      }

      if (customerStatusQuery.length > 1) {
        returnQueryFilter.push(
          'customer_status=' + 'in:' + customerStatusQuery.join(',')
        );
      } else if (
        customerStatusQuery.length === 1 &&
        customerStatusQuery[0] !== ''
      ) {
        returnQueryFilter.push('customer_status=' + customerStatusQuery[0]);
      }
      if (formatQuery.length > 1) {
        returnQueryFilter.push('format=' + 'in:' + formatQuery.join(','));
      } else if (formatQuery.length === 1 && formatQuery[0] !== '') {
        returnQueryFilter.push('format=' + formatQuery[0]);
      } else {
        returnQueryFilter.push('format=in:1,2,3,5,6');
      }

      if (categoryQuery.length > 1) {
        returnQueryFilter.push('category=in:' + categoryQuery.join(','));
      } else if (categoryQuery.length === 1 && categoryQuery[0] !== '') {
        returnQueryFilter.push('category=' + categoryQuery[0]);
      }

      if (job_id.length > 1) {
        returnQueryFilter.push('job_id=in:' + job_id.join(','));
      } else if (job_id.length === 1 && job_id[0] !== '') {
        returnQueryFilter.push('job_id=' + job_id[0]);
      }

      if (payments.length > 1) {
        returnQueryFilter.push('payment_status=in:' + payments.join(','));
      } else if (payments.length === 1 && payments[0] !== '') {
        returnQueryFilter.push('payment_status=' + payments[0]);
      }

      if (plans.length > 1) {
        returnQueryFilter.push('plan=in:' + plans.join(','));
      } else if (plans.length === 1 && plans[0] !== '') {
        returnQueryFilter.push('plan=' + plans[0]);
      }
    } else if (selectedFilter == null) {
      //No filter needed
    } else {
      returnQueryFilter.push('format=in:1,2,5,6');
    }
    if (parseInt(count) > 0) {
      returnQueryFilter.push('count=' + parseInt(count));
    }
    if (selectedSort !== '') {
      returnQueryFilter.push('sort=' + selectedSort);
    }
    if (!isNaN(pageNum) && pageNum !== '') {
      returnQueryFilter.push('page=' + pageNum);
    }
    if (returnQueryFilter.length > 0) {
      return returnQueryFilter.join('&');
    }
    return '';
  },
  formatData: function (model) {
    if (typeof model === 'object') {
      const keys = Object.keys(model);
      if (Array.isArray(keys)) {
        for (let index = 0; index < keys.length; index++) {
          if (true === this.hasToBeFormatted(keys[index])) {
            model[keys[index]] = this.arrayToIds(model[keys[index]]);
          }
        }
      }
      return model;
    } else {
      return {};
    }
  },
  shorten(inputString, maxChar, suffix = '') {
    if (typeof inputString === 'string' && inputString.length > 0) {
      if (inputString.length > maxChar) {
        let temp = inputString.substr(0, maxChar);
        let lastSpace = temp.lastIndexOf(' ');
        return inputString.substr(0, lastSpace) + suffix;
      } else {
        return inputString;
      }
    }
  },
  colorIsLight(hexColor) {
    // hexColor RGB
    let r1, g1, b1;

    if (typeof hexColor === 'string') {
      r1 = parseInt(hexColor.substr(1, 2), 16);
      g1 = parseInt(hexColor.substr(3, 2), 16);
      b1 = parseInt(hexColor.substr(5, 2), 16);

      // Black RGB
      let blackColor = '#000000';
      let r2BlackColor = parseInt(blackColor.substr(1, 2), 16);
      let g2BlackColor = parseInt(blackColor.substr(3, 2), 16);
      let b2BlackColor = parseInt(blackColor.substr(5, 2), 16);

      // Calc contrast ratio
      let l1 =
        0.2126 * Math.pow(r1 / 255, 2.2) +
        0.7152 * Math.pow(g1 / 255, 2.2) +
        0.0722 * Math.pow(b1 / 255, 2.2);

      let l2 =
        0.2126 * Math.pow(r2BlackColor / 255, 2.2) +
        0.7152 * Math.pow(g2BlackColor / 255, 2.2) +
        0.0722 * Math.pow(b2BlackColor / 255, 2.2);

      let contrastRatio = 0;
      if (l1 > l2) {
        contrastRatio = parseInt((l1 + 0.05) / (l2 + 0.05));
      } else {
        contrastRatio = parseInt((l2 + 0.05) / (l1 + 0.05));
      }
      return contrastRatio > 5;
    }
    return false;
  },
  capitalize: function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  lowernormalize: function (string) {
    return string
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  },
  formatObject(input, functionFormat) {
    if (['undefined', 'symbol', 'function'].includes(typeof input)) {
      console.error('Invalid input `' + input + '`');
    }

    if (
      input === null ||
      ['boolean', 'number', 'bigint', 'string'].includes(typeof input)
    ) {
      return input;
    }

    let out = [];

    if (Array.isArray(input)) {
      for (let i = 0; i < input.length; i++) {
        out.push(this.formatObject(input[i], functionFormat));
      }
      return out;
    }

    return Object.keys(input).reduce((output, key) => {
      let formattedKey = this[functionFormat](key);
      output[formattedKey] = this.formatObject(input[key], functionFormat);
      return output;
    }, {});
  },
  toKebab: function (string) {
    return string
      .split('')
      .map((letter) => {
        if (/[A-Z]/.test(letter)) {
          return ` ${letter.toLowerCase()}`;
        }
        return letter;
      })
      .join('')
      .trim()
      .replace(/[_\s]+/g, '-');
  },
  toSnake: function (string) {
    return string
      .split('')
      .map((letter) => {
        if (/[A-Z]/.test(letter)) {
          return ` ${letter.toLowerCase()}`;
        }
        return letter;
      })
      .join('')
      .trim()
      .replace(/[_\s]+/g, '_');
  },
  toCamel: function (string) {
    return this.toKebab(string)
      .split('-')
      .map((word, index) => {
        if (index === 0) {
          return word;
        }
        return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join('');
  },
  toPascal: function (string) {
    return this.toKebab(string)
      .split('-')
      .map((word) => {
        return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join('');
  },
  toTitle: function (string) {
    return this.toKebab(string)
      .split('-')
      .map((word) => {
        return word.slice(0, 1).toUpperCase() + word.slice(1);
      })
      .join(' ');
  },
  toSlug: function (value) {
    value = value.toLowerCase();
    // accent substitutions
    value = value.replace(/[á|ã|â|à]/gi, 'a');
    value = value.replace(/[é|ê|è]/gi, 'e');
    value = value.replace(/[í|ì|î]/gi, 'i');
    value = value.replace(/[õ|ò|ó|ô]/gi, 'o');
    value = value.replace(/[ú|ù|û]/gi, 'u');
    value = value.replace(/[ç]/gi, 'c');
    value = value.replace(/[ñ]/gi, 'n');
    value = value.replace(/[á|ã|â]/gi, 'a');
    // replaces spaces and other characters with - (hyphen)
    value = value.replace(/\W/gi, '-');
    // remove - (hífen) duplicados
    // value = value.replace(/(\-)\1+/gi, "-");
    value = value.replace(/-{2,}/g, '-');
    value = value.replace(/^-/, '');
    return value;
  },
  snakeCaseToSentence: function (string) {
    const interim = string.replace(/_/g, ' ');
    return interim.slice(0, 1).toUpperCase() + interim.slice(1);
  },
  toSentence: function (string) {
    const interim = this.toKebab(string).replace(/-/g, ' ');
    return interim.slice(0, 1).toUpperCase() + interim.slice(1);
  },
  toDotSeparated: function (string) {
    return this.toKebab(string).replace(/-/g, '.');
  },
  toTimecode: function (sec) {
    let sec_num = parseInt(sec, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  },
  toTimecodeMasked: function (sec) {
    const regex = /:/gi;
    const string = this.toTimecode(sec).replace(regex, '');
    const num = parseInt(string, 10);
    return string;
  },
  toSeconds: function (timecode) {
    let timeArray = timecode?.split(':') ?? [];
    if (timeArray.length > 0) {
      let hours = parseInt(timeArray[0]) * 60 * 60;
      let minutes = parseInt(timeArray[1]) * 60;
      let seconds = parseInt(timeArray[2]);
      let totalTime = hours + minutes + seconds;
      return totalTime;
    }
    return 0;
  },
  toInitial: function (string, toLowercase = true) {
    let initial = string.charAt(0);
    if (toLowercase) {
      initial = initial.toLowerCase();
    }
    return initial;
  },
  toAbbr: function (string, charNum = 1) {
    return string.slice(0, Math.min(charNum, string.length)).toLowerCase();
  },
  isDeviceTV: function (device) {
    return this.getTVDevices().includes(device);
  },
  formatPrice: (amount, currency, locale) => {
    return currency
      ? new Intl.NumberFormat(locale, {
          style: 'currency',
          currency: currency,
        }).format(amount)
      : null;
  },
  convertIntPriceToDecimalPrice: (
    amount,
    currency,
    forceDecimalNumber = false
  ) => {
    if (currency.zero_decimal) {
      return amount;
    } else if (currency.three_decimal) {
      if (forceDecimalNumber) {
        return parseFloat(amount / 1000).toFixed(3);
      }
      return amount / 1000;
    } else {
      if (forceDecimalNumber) {
        return parseFloat(amount / 100).toFixed(2);
      }
      return amount / 100;
    }
  },
  convertDecimalPriceToIntPrice: (amount, currency) => {
    if (currency.zero_decimal) {
      return amount;
    } else if (currency.three_decimal) {
      return Math.round(amount * 1000);
    } else {
      return Math.round(amount * 100);
    }
  },
  forceDecimalNumber: (amount, currency) => {
    if (currency.zero_decimal) {
      return amount;
    } else if (currency.three_decimal) {
      return parseFloat(amount).toFixed(3);
    } else {
      return parseFloat(amount).toFixed(2);
    }
  },
  getCurrencySymbol: (currency) => getSymbolFromCurrency(currency) || currency,
  getTVDevices: function () {
    return devices.reduce((arr, x) => {
      if (x?.isTV) {
        arr.push(x.id);
      }
      return arr;
    }, []);
  },
  hexToRgb(color, full = false) {
    color = color.replace('#', '');
    const r = parseInt(color.substring(0, 2), 16);
    const g = parseInt(color.substring(2, 4), 16);
    const b = parseInt(color.substring(4, 6), 16);
    return full ? `rgb(${r}, ${g}, ${b})` : `${r}, ${g}, ${b}`;
  },
  hasText(text) {
    return typeof text !== 'undefined' && text !== null && text !== '';
  },
  detectAdblock: function () {
    const baitAd = {
      url: 'https://otto-static.cdn.vodfactory.com/_otto_probe/neutral.js?&ad_height=',
      id: '837jlaBksSjd9jh',
    };

    function canLoadBaitAd(obj) {
      return new Promise(function (resolve) {
        var script = document.createElement('script');
        script.onload = function () {
          if (document.getElementById(obj.id)) {
            resolve(true);
          } else {
            resolve(false);
          }
        };
        script.onerror = function (error) {
          resolve(false);
        };
        script.src = obj.url;
        script.id = obj.id;
        document.body.appendChild(script);
      });
    }

    return new Promise(function (resolve, reject) {
      canLoadBaitAd(baitAd)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  isShallowEqual: function (objA, objB, compare, compareContext) {
    if (isProxy(objA)) {
      objA = toRaw(objA);
    }
    if (isProxy(objB)) {
      objB = toRaw(objB);
    }

    var ret = compare ? compare.call(compareContext, objA, objB) : void 0;

    if (ret !== void 0) {
      return !!ret;
    }

    if (Object.is(objA, objB)) {
      return true;
    }

    if (
      typeof objA !== 'object' ||
      !objA ||
      typeof objB !== 'object' ||
      !objB
    ) {
      return false;
    }

    var keysA = Object.keys(objA);
    var keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    var bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);

    // Test for A's keys different from B.
    for (var idx = 0; idx < keysA.length; idx++) {
      var key = keysA[idx];

      if (!bHasOwnProperty(key)) {
        return false;
      }

      var valueA = objA[key];
      var valueB = objB[key];

      ret = compare
        ? compare.call(compareContext, valueA, valueB, key)
        : void 0;

      if (ret === false || (ret === void 0 && !Object.is(valueA, valueB))) {
        return false;
      }
    }

    return true;
  },
};

export default utils;
