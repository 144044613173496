<template>
  <div class="flex flex-col">
    <BaseButton
      nude
      font-weight="normal"
      text-align="left"
      class="!px-0"
      size="xxs"
      @click="$emit('toggle')"
    >
      <Icon :icon="assetType" size="md" />
      <span class="text-xs line-clamp-1 break-all">{{
        $tc('videos.' + assetType + '.count', assets.length)
      }}</span>
      <Icon
        icon="caret-down"
        class="transform transition-transform ease-in-out"
        size="xs"
        :class="{ '-rotate-180': show }"
      />
    </BaseButton>

    <ul v-if="show">
      <li class="first:mt-8 mt-4">
        <BaseButton
          nude
          class="!px-0"
          font-weight="normal"
          size="xxs"
          icon-before="add"
          @click="showModalAddAsset()"
          :disabled="!canEditAsset"
        >
          <Icon icon="add" size="sm" />
          <span class="text-xs font-weight-medium">{{ $t('videos.' + assetType + '.button.add') }}</span>
        </BaseButton>
      </li>
      <li
        v-for="(asset, i) in assets"
        :key="i"
        class="otto-base-ui-component size-xs flex items-center mt-2 theme-gray !pl-6"
      >
        <Tag size="xs" type="filled" weight="normal" tabindex="-1" :disabled="!canEditAsset">
          <span class="inline-flex break-all line-clamp-1 text-xs">{{
            asset.display
          }}</span>
          <div
            class="cursor-pointer"
            @click="removeThisAsset(asset)"
            :disabled="!canEditAsset || asset.removable === false"
          >
            <Icon icon="close" size="xs" />
          </div>
        </Tag>
      </li>
    </ul>
  </div>
</template>

<script>
import { cloneDeep, isEmpty } from 'lodash';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import BaseButton from '@/components/button/BaseButton.vue';
import Icon from '@/components/icon/Icon.vue';
import Tag from '@/components/tag/Tag.vue';
import subtitleTypes from '@/data/subtitleTypes.js';

export default {
  name: 'VideoAssets',
  components: {
    BaseButton,
    Icon,
    Tag,
  },
  props: {
    assetType: {
      type: String,
      required: true,
    },
    pageType: {
      type: String,
      required: true,
    },
    videoData: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
    },
    partnerId: {
      type: String,
      default: null
    },
  },
  computed: {
    assets() {
      const partnerWithSubtype = this.assetType === 'subtitles' && ['molotov', 'amazon'].includes(this.partnerId);
      const self = this;

      return (this.videoData[this.assetType] ?? []).map(function (asset) {
        const lang = self.getLanguageName(asset.locale);
        const type = self.$t('videos.' + self.assetType + '.types.' + (asset.type ?? 'normal'));
        const display = `${lang}` + (partnerWithSubtype ? ` (${type})` : '');

        return {
          id: asset.id,
          format: asset.format,
          locale: asset.locale,
          lang: lang,
          display: display,
          type: asset.type ?? 'normal',
          key: asset.locale + '-' + (asset.type ?? 'normal'),
        };
      });
    },
    canEditAsset() {
      return [  
        'main-subtitles-program-otto',
        'main-subtitles-video-otto',
        'bonus-subtitles-program-otto',
        'bonus-subtitles-video-otto',
        'trailer-subtitles-program-otto',
        'trailer-subtitles-video-otto',
        // audio tracks are not editable for otto videos

        'main-subtitles-program-molotov',
        'bonus-subtitles-program-molotov',
        'trailer-subtitles-program-molotov',
        // audio tracks are not editable for molotov videos + there is no video page for molotov

        'main-subtitles-program-amazon',
        'main-audios-program-amazon',
        'bonus-subtitles-program-amazon',
        'bonus-audios-program-amazon',
        'trailer-subtitles-program-amazon',
        'trailer-audios-program-amazon',
        // there is no video page for amazon
    
        // subtitles and audios are not editable at all for betv and free
      ].includes(this.videoData.type + '-' + this.assetType + '-' + this.pageType + '-' + (this.partnerId ?? 'otto'));
    },
    ...mapGetters({
      allLanguages: 'languages/groupedAllLanguages',
    }),
  },
  methods: {
    getLanguageName(iso) {
      const temp = this.allLanguages.filter((x) => x.iso_639_3 === iso);
      if (temp.length > 0) {
        return temp[0].title;
      } else {
        return iso;
      }
    },
    removeThisAsset(asset) {
      if (this.pageType === 'video') {
        this.removeCurrentNewVideoAsset({
          assetType: this.assetType,
          assetId: asset.id
        });
      } else {
        this.removeMovieVideoAsset({
          partnerId: this.partnerId,
          videoType: this.videoData.type,
          videoId: this.videoData.id,
          assetType: this.assetType,
          assetId: asset.id,
        });
      }
    },
    showModalAddAsset() {
      const videoData = cloneDeep(this.videoData);
  
      const usedLocales = this.assets.map((asset) => asset.key);

      const modalParams = {
        videoData: videoData,
        assetType: this.assetType,
        type: this.pageType,
        programId: videoData.program_id,
        videoId: videoData.id,
        videoType: videoData.type,
        partnerId: this.partnerId,
        usedLocales: usedLocales,
      };
  
      const modalName = isEmpty(this.partnerId) || this.partnerId === 'otto' ? 'ModalAddVideoAssetFromComputer' : 'ModalAddVideoAssetFromFtp';

      this.showModal({
        componentName: modalName,
        componentParams: modalParams,
        size: 'md',
      });
    },
    ...mapMutations('videos', {
      removeCurrentNewVideoAsset: 'removeCurrentNewVideoAsset',
    }),
    ...mapMutations('programs', {
      removeMovieVideoAsset: 'removeMovieVideoAsset'
    }),
    ...mapMutations(['showModal']),
  },
};
</script>
