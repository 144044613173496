/**
 * @prettier
 */
import { isEmpty, isNil } from 'lodash';

import Live from '@/models/Live';
import Picture from '@/models/Picture';
import Video from '@/models/Video';
import videosTypes from '@/data/videosTypes';
import { amazonTerritories, partners } from '@/data/partners.js';
import moment from 'moment-timezone';

export class Movie {
  id;
  parent = null;
  parentId = null;
  title = '';
  status = 'incomplete';
  publication = {
    is_free: false,
    publish_at: undefined,
    unpublish_at: undefined,
  };
  type = '';
  program_type_id;
  group_plan_id;
  category = [];
  country = [];
  synopsis = '';
  ratinglevel = {};
  level = {};
  duration = '';
  date = '';
  version = {};
  cast = [];
  review = '';
  gear = [];
  tag = [];
  note = '';
  serieId = null;
  seasonId = null;
  seasons = [];
  video = {
    main: [],
    bonus: [],
    trailer: [],
    live: {},
  };
  live = null;
  picture = {};
  geoblocking = {
    all: true,
  };
  programmationDates = {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  };
  file = [];

  // partners (amazon, betv, molotov, free)
  partners_metas = {};
  partnersPackages = [];
  partners_publications = null;
  free_video = null;
  amazon_video = null;
  betv_video = null;
  molotov_video = null;

  constructor({
    id,
    parent = null,
    serie_program_id = null,
    title = '',
    status = 'incomplete',
    publication = { is_free: false },
    partners_publications = null,
    type = '',
    program_type_id = '',
    group_plan_id = '',
    category = [],
    country = [],
    synopsis = '',
    ratinglevel = {},
    level = {},
    duration = '',
    date = '',
    version = {},
    cast = [],
    review = '',
    gear = [],
    tag = [],
    note = '',
    serie_id = null,
    season_id = null,
    seasons = [],
    video = {
      main: [],
      bonus: [],
      trailer: [],
      episode: [],
    },
    live = {},
    liveConfig = {},
    picture = {},
    geoblocking = {
      all: true,
    },
    file = [],
    programmationDates = {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
    },
    avod = {
      megaban_header: 'none',
      megaban_footer: 'none',
      video_preroll: 'none',
    },
    rank,
    show_episodes_rank = false,
    
    // partners (amazon, betv, molotov, free)
    partners_metas = {},
    free_video = {
      main: [],
    },
    amazon_video = {
      main: [],
      trailer: [],
    },
    betv_video = {
      main: [],
    },
    molotov_video = {
      main: [],
    },

  }) {
    this.id = id;
    this.parent = parent;
    this.parentId = serie_program_id;
    this.title = title;
    this.status = status;
    this.type = type;
    this.program_type_id = program_type_id;
    this.category = category;
    this.country = country;
    this.synopsis = synopsis;
    this.ratinglevel = ratinglevel;
    this.level = level;
    this.duration = duration;
    this.date = date;
    this.version = version;
    this.cast = cast;
    this.review = review;
    this.gear = gear;
    this.tag = tag;
    this.note = note;
    this.serieId = serie_id;
    this.seasonId = season_id;
    this.seasons = seasons;
    this.picture = picture;
    this.setVideo('video', video);
    this.setVideo('free_video', free_video);
    this.setVideo('amazon_video', amazon_video);
    this.setVideo('betv_video', betv_video);
    this.setVideo('molotov_video', molotov_video);
    this.setLive(live);
    this.setPicture(picture);
    this.setMetadatas(...arguments);
    this.geoblocking = geoblocking;
    this.programmationDates = programmationDates;
    this.file = file;
    this.setPublication(publication);
    this.rank = rank
    this.avod = avod;
    this.show_episodes_rank = show_episodes_rank;
    this.group_plan_id = group_plan_id;
    // partners (amazon, betv, molotov, free)
    this.setPartnersMetadatas(partners_metas);
    this.setPartnersPublications(partners_publications);
  };

  setVideo(label, video) {
    this[label] = {};

    if (typeof video === 'object') {
      videosTypes.forEach((details) => {
        this[label][details.type] = [];
  
        (video?.[details?.type] ?? []).forEach((item) => {
          this[label][details.type].push(new Video(item));
        });
      });
    } else {
      videosTypes.forEach((details) => {
        this[label][details.type] = [];
      });
    }
  };

  setLive(live) {
    if (this.program_type_id === 5) {
      this.live = new Live(live);
      this.liveConfig = new Live(live);
    }
  };

  setPicture(picture) {
    this.picture = Picture.getDefaultPicture('program');
    const pictureTypes = Picture.getDefaultPictureTypesList('program');
    if (typeof picture === 'object') {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = picture[pictureType] ?? [];
      });
    } else {
      pictureTypes.forEach((pictureType) => {
        this.picture[pictureType] = [];
      });
    }
  };

  setPublication(publication) {
    if (!publication || (Array.isArray(publication) && !publication.length)) {
      this.publication = {
        fr: {
          country_id: 250,
          is_free: false,
          publish_at: undefined,
          unpublish_at: undefined,
          publish_at_utc: undefined,
          unpublish_at_utc: undefined
        },
      };
    } else {
      this.publication = publication;
      Object.keys(publication).forEach((key) => {
        this.publication[key].publish_at_utc = publication[key].publish_at ? moment(publication[key].publish_at).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null;
        this.publication[key].unpublish_at_utc = publication[key].unpublish_at ? moment(publication[key].unpublish_at).utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z' : null;
      });
    }
  };

  setMetadatas(raw) {
    const keys = Object.keys(raw);
    keys.forEach((element) => {
      if (!(element in this) && element !== 'partners_metas') {
        if (
          Array.isArray(raw[element]) &&
          !isEmpty(raw[element]) &&
          isNil(raw[element][0].jobs)
        ) {
          this[element] = raw[element].map((item) => item.id);
        } else {
          this[element] = raw[element];
        }
      }
    });
  };

  // partners (amazon, betv, molotov, free)
  setPartnersPublications(publications) {
    if (!publications) return;
    let partnersPublications = {};
  
    const getPublication = function (partnerId, country) {
      const pub = publications?.[partnerId]?.find((pub) => pub.country === country);
      if (pub) return pub; 
      return {
        country: country,
        environment: partnerId,
        rights_starts_at: null,
        rights_ends_at: null,
      }
    };

    partners.forEach((partner) => {
      partnersPublications[partner.id] = [];
      if (partner.id === 'amazon') {
        amazonTerritories.forEach((cy) => {
          const country = cy.id.toLowerCase();
          const pub = getPublication(partner.id, country);
          if (pub) {
            partnersPublications[partner.id].push(pub);
          } else {
            partnersPublications[partner.id] = [pub];
          }
        });
      } else {
        const country = partner.id === 'betv' ? 'be' : 'fr';
        const pub = getPublication(partner.id, country);
        if (pub) {
          partnersPublications[partner.id].push(pub);
        } else {
          partnersPublications[partner.id] = [pub];
        }
      }
        /*
        if (existingEnvironment) {
            existingEnvironment.enabled = 
              this.partnersPackages?.find((pk) => pk.id === partner.id)?.status === 'success' && 
              existingEnvironment.rights_ends_at > new Date().toISOString();
            partnersPublications[partner.id].push(existingEnvironment);
          }
        */
    });
    this.partners_publications = partnersPublications;
  };

  setPartnersMetadatas(partners_metas) {
    if (isEmpty(partners_metas)) return;
    for (const [key, value] of Object.entries(partners_metas)) {
      if (Array.isArray(value) &&
        !isEmpty(value) &&
        isNil(value[0].jobs)
      ) {
        this.partners_metas[key] = value.map((item) => item.id);
      } else {
        this.partners_metas[key] = value;
      }
    }
  };
}

export function createMovie(data) {
  return Object.freeze(new Movie(data));
}

export default Movie;
