import { businessRuleService } from "@/services/content/businessRules.service";

const state = () => ({
  loading: true,
  items: [],
  rules: [],
});

const getters = {};

const actions = {
  getItems({ commit, dispatch }, id) {
    commit('setLoading', true);
    return businessRuleService.getItems(id).then(
      (items) => {
        commit('getItemsSuccess', { id, items });
        commit('setLoading', false);
        return Promise.resolve(items);
      },
      ({ error }) => {
        commit('getItemsFailure', error);
        commit('setLoading', false);
        return Promise.reject(error);
      }
    );
  },
  getBusinessRules({ commit, dispatch }) {
    return businessRuleService.getBusinessRules().then(
      (rules) => {
        commit('getBusinessRulesSuccess', rules);
        return Promise.resolve(rules);
      },
      ({ error }) => {
        commit('getBusinessRulesFailure', error);
        return Promise.reject(error);
      }
    );
  }
};

const mutations = {
  getItemsSuccess(state, { id, items }) {
    state.items = items;
  },
  getItemsFailure(state) {
    state.items = [];
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  getBusinessRulesSuccess(state, rules) {
    state.rules = rules;
  },
  getBusinessRulesFailure(state) {
    state.rules = [];
  }
};

export const businessRules= {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
